import React from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Wrapper,
  Contact,
  Icon,
  Links,
  Social,
  SecondaryItem,
  SecondaryItemEx,
} from './index.styled'
import fhnFile from 'static/fairhousingnotice.pdf'
import sopFile from 'static/NY-StandardOperatingProcedures.pdf'

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { phone, email, address, secondary, insta, facebook },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          phone
          email
          address
          facebook
          secondary {
            title
            url
          }
          insta
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Links className="secondary">
        {secondary.map((l, i) => {
          return (
            <SecondaryItem key={`links-${i}`} to={l.url}>
              {l.title}
            </SecondaryItem>
          )
        })}
        <SecondaryItemEx href={fhnFile} target={'_blank'} as="a">
          FAIR HOUSING NOTICE
        </SecondaryItemEx>
        <SecondaryItemEx href={sopFile} target={'_blank'} as="a">
          STANDARD OPERATING PROCEDURES
        </SecondaryItemEx>
      </Links>
      <Social className={'forMobile'}>
        <Icon href={insta} target={'_blank'} as="a" />
        <Icon className={'fb'} href={facebook} target={'_blank'} as="a" />
      </Social>
      <Contact>
        <div>{address}</div>
        <a href={`tel:${phone}`}>{phone}</a>
        <a href={`mailto:${email}`}>{email}</a>
        <Icon className={'forWeb'} href={insta} target={'_blank'} as="a" />
        <Icon
          className={'forWeb fb'}
          href={facebook}
          target={'_blank'}
          as="a"
        />
      </Contact>
    </Wrapper>
  )
}

export default Footer
